import React, { useEffect, useState  } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Navigate,
	useLocation,
	useNavigate
} from "react-router-dom";
// Define the Login form component

const LoginForm = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [alertMessage, setAlertMessage2] = useState("");

	useEffect(() => {
	  console.log('Current location is ', location);
	}, [location]);

	// Whenever an input changes value, change the corresponding state variable
	const handleInputChange = (event) => {
		event.preventDefault();
		const target = event.target;
		this.setState({
			[target.name]: target.value,
		});
	}

	const handleSubmit = async(event) => {
		event.preventDefault();
		// Reset the alert to empty
		// setAlertMessage();
		// Call Userfront.login()
		// Userfront.login({
		// 	method: "password",
		// 	userName: this.state.userName,
		// 	password: this.state.password,
		// }).catch((error) => {
		// 	this.setAlertMessage(error.message);
		// });
		// await this.getInfo();

		fetch("https://dbdiamserver.apavloff.ru/login", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ login: userName, password: password }),
			}).then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log(data);
				if(data.ok) {
					console.log("navigate");
					navigate('/database', { replace: false })
				}
			});
	}

	const getInfo = async () => {
		// console.log(Userfront.tokens);
		// const res = await fetch("http://127.0.0.1:5000", {
		// 	method: "GET",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		Authorization: `Bearer ${Userfront.tokens.accessToken}`,
		// 	},
		// });

		// console.log(res);
	}

	const setAlertMessage = (message) => {
		setAlertMessage(message);
	}

	return (
		<div>
			<Alert message={alertMessage} />
			<form onSubmit={handleSubmit}>
				<label>
					Email or username
					<input
						name='userName'
						type='text'
						value={userName}
						onChange={(e) => {
							console.log(e.target.value)
							setUserName(e.target.value)
						}}
					/>
				</label>
				<label>
					Password
					<input
						name='password'
						type='password'
						value={password}
						onChange={(e) => {
							console.log(e.target.value)
							setPassword(e.target.value)
						}}
					/>
				</label>
				<button type='submit'>Log in</button>
			</form>
		</div>
	)
}

// Define the alert component
class Alert extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		if (!this.props.message) return "";
		return <div id='alert'>{this.props.message}</div>;
	}
}

export default LoginForm;
