import React, { useEffect, useState  } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Navigate,
	useLocation,
	useNavigate
} from "react-router-dom";
import Table from "react-bootstrap/Table";

import { FaTrash } from "react-icons/fa";

// Define the Login form component

const Sessions = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [dbData, setDbData] = useState([])

	useEffect(() => {
	  console.log('Current location is ', location);
	}, [location]);

	useEffect(() => {
		loaddb()
	}, []);

	const setAlertMessage = (message) => {
		setAlertMessage(message);
	}

	const showDatabase = () => {
		navigate('/database', { replace: false })
	}

	const fillTable = () => {
		console.log(dbData)
		const res = dbData.map((value, index) => {
			return (
				<tr>
					<td>{value.id}</td>
					<td>{value.userId}</td>
					<td>{value.startSession}</td>
					<td>{value.finishSession}</td>
					<td>{value.projectName}</td>
				</tr>
			)
		})
		return (
			res
		)
	}

	const loaddb = () => {
		fetch("https://dbdiamserver.apavloff.ru/getSessions", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then((response) => {
			return response.json();
		})
		.then((data) => {
			console.log(data);
			setDbData(data.result)
		});
	}

	return (
		<div>
			<button onClick={() => showDatabase()}>database</button>
			<Table striped bordered hover>
					<thead>
						<tr>
							<th>id</th>
							<th>userId</th>
							<th>startSession</th>
							<th>finishSession</th>
							<th>projectName</th>
						</tr>
					</thead>
					<tbody>
						{fillTable()}
					</tbody>
			</Table>
		</div>
	)
}

export default Sessions;
