import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Navigate,
	useLocation,
} from "react-router-dom";

import SignupForm from "./components/SignUp";
import LoginForm from "./components/Login";
import Database from "./components/Database";
import Sessions from "./components/Sessions";


import "bootstrap/dist/css/bootstrap.min.css";


export default function App() {
	return (
		<Router>
			<div>
				{/* <nav>
					<ul>
						<li>
							<Link to='/'>Home</Link>
						</li>
						<li>
							<Link to='/login'>Login</Link>
						</li>
						<li>
							<Link to='/reset'>Reset</Link>
						</li>
						<li>
							<Link to='/dashboard'>Dashboard</Link>
						</li>
					</ul>
				</nav> */}

				<Routes>
					{/* <Route path='/login' element={<Home />} /> */}
					<Route path='/' element={<Login />} />
					{/* <Route path='/reset' element={<PasswordReset />} /> */}
					<Route
						path='/database'
						element={<DatabaseEdit />
						}
					/>
					<Route
						path='/sessions'
						element={<SessionsForm />
						}
					/>
				</Routes>
			</div>
		</Router>
	);
}

// function Home() {
// 	return (
// 		<div>
// 			<h2>Home</h2>
// 			<SignupForm />
// 		</div>
// 	);
// }

function Login() {
	return (
		<div>
			<h2>Login</h2>
			<LoginForm />
		</div>
	);
}

function DatabaseEdit() {
	return (
		<div>
			<h2>Database</h2>
			<Database />
			{/*<pre>{userData}</pre>
			<button onClick={Userfront.logout}>Logout</button>*/}
		</div>
	);
}

function SessionsForm() {
	return (
		<div>
			<h2>Sessions</h2>
			<Sessions />
			{/*<pre>{userData}</pre>
			<button onClick={Userfront.logout}>Logout</button>*/}
		</div>
	);
}

// function Dashboard() {
// 	return (
// 		<div>
// 			<h2>Dashboard</h2>
// 			{/*<pre>{userData}</pre>
// 			<button onClick={Userfront.logout}>Logout</button>*/}
// 		</div>
// 	);
// }
