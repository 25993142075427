import React, { useEffect, useState  } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Navigate,
	useLocation,
	useNavigate
} from "react-router-dom";
import Table from "react-bootstrap/Table";

import { FaTrash } from "react-icons/fa";

// Define the Login form component

const Database = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("");

	const [alertMessage, setAlertMessage2] = useState("");
	const [dbData, setDbData] = useState([])

	useEffect(() => {
	  console.log('Current location is ', location);
	}, [location]);

	useEffect(() => {
		loaddb()
	  }, []);

	const setAlertMessage = (message) => {
		setAlertMessage(message);
	}

	const loaddb = () => {
		fetch("https://dbdiamserver.apavloff.ru/getdb", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then((response) => {
			return response.json();
		})
		.then((data) => {
			console.log(data);
			setDbData(data.result)
		});
	}

	const showSessions = () => {
		navigate('/sessions', { replace: false })
	}

	const removeItem = (id, index) => {
		console.log(index)
		fetch("https://dbdiamserver.apavloff.ru/removeItemDB", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({id: id})
		}).then((response) => {
			return response.json();
		})
		.then((data) => {
			console.log(data);
			// setDbData(data.result)
			loaddb()
		});
	}

	const fillTable = () => {
		console.log(dbData)
		const res = dbData.map((value, index) => {
			return (
				<tr>
					<td>{value.id}</td>
					<td>{value.name}</td>
					<td>{value.password}</td>
					<td>{value.email}</td>
					<td>{value.role}</td>
					<td><button onClick={() => removeItem(value.id, index)}>
						<FaTrash />
						</button></td>
				</tr>
			)
		})
		return (
			res
		)
	}

	const handleSubmit = async(event) => {
		event.preventDefault();
		fetch("https://dbdiamserver.apavloff.ru/insert", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({userName, password, email, role})
		}).then((response) => {
			return response.json();
		})
		.then((data) => {
			console.log(data);
			// setDbData(data.result)
			loaddb()
		});

	}

	const insertUser = () => {
		return (
			<form onSubmit={handleSubmit}>
				<label>
					User
					<input
						name='userName'
						type='text'
						value={userName}
						onChange={(e) => {
							console.log(e.target.value)
							setUserName(e.target.value)
						}}
					/>
				</label>
				<label>
					Password
					<input
						name='password'
						type='password'
						value={password}
						onChange={(e) => {
							console.log(e.target.value)
							setPassword(e.target.value)
						}}
					/>
				</label>
				<label>
					Email
					<input
						name='email'
						type='email'
						value={email}
						onChange={(e) => {
							console.log(e.target.value)
							setEmail(e.target.value)
						}}
					/>
				</label>
				<label>
					Role
					<input
						name='role'
						type='text'
						value={role}
						onChange={(e) => {
							console.log(e.target.value)
							setRole(e.target.value)
						}}
					/>
				</label>
				<button type='submit'>Insert</button>
			</form>
		)
	}

	return (
		<div>
			<button onClick={() => loaddb()}>Load db</button>
			<button onClick={() => showSessions()}>Sessions</button>
			<Table striped bordered hover>
					<thead>
						<tr>
							<th>id</th>
							<th>name</th>
							<th>password</th>
							<th>email</th>
							<th>role</th>
							<th>remove</th>
						</tr>
					</thead>
					<tbody>
						{fillTable()}
					</tbody>
			</Table>
			{insertUser()}
			{/* <Alert message={alertMessage} />
			<form onSubmit={handleSubmit}>
				<label>
					Email or username
					<input
						name='userName'
						type='text'
						value={userName}
						onChange={(e) => {
							console.log(e.target.value)
							setUserName(e.target.value)
						}}
					/>
				</label>
				<label>
					Password
					<input
						name='password'
						type='password'
						value={password}
						onChange={(e) => {
							console.log(e.target.value)
							setPassword(e.target.value)
						}}
					/>
				</label>
				<button type='submit'>Log in</button>
			</form> */}
		</div>
	)
}

// Define the alert component
class Alert extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		if (!this.props.message) return "";
		return <div id='alert'>{this.props.message}</div>;
	}
}

export default Database;
