import React from "react";

// Define the Signup form component
class SignupForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			accountName: "",
			password: "",
			passwordVerify: "",
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		console.log(localStorage)
	}

	// Whenever an input changes value, change the corresponding state variable
	handleInputChange(event) {
		event.preventDefault();
		const target = event.target;
		this.setState({
			[target.name]: target.value,
		});
	}

	// Handle the form submission by calling Userfront.signup()
	handleSubmit(event) {
		event.preventDefault();
		// Call Userfront.signup()
		var that = this;
		console.log(this.state.email)
		console.log(this.state.password)
		console.log(this.state.accountName)
		// Userfront.signup({
		// 	method: "password",
		// 	email: this.state.email,
		// 	password: this.state.password,
		// 	data: {
		// 		accountName: this.state.accountName,
		// 	},
		// 	username: this.state.accountName,
		// 	name: this.state.accountName,
		// 	handleTokens: function (tokens, data) {
		// 		console.log(tokens);
		// 		console.log(data);
		// 		that.sendData(tokens, data);
		// 	},
		// });
		this.sendData("hasgfkjgfa", "oirfweqoirio");
		// console.log(Userfront.tokens);
	}

	async sendData(tokens, data) {
		// const res = await fetch("http://127.0.0.1:5000/signup", {
		// 	method: "POST",
		// 	headers: {
		// 		Accept: "application/json",
		// 		"Content-Type": "application/json",
		// 	},
		// 	body: JSON.stringify({ tokens, data }),
		// });
		// console.log(res);
	}

	render() {
		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<label>
						Email address
						<input
							name='email'
							type='email'
							value={this.state.email}
							onChange={this.handleInputChange}
						/>
					</label>
					<label>
						Account name (custom field)
						<input
							name='accountName'
							type='text'
							value={this.state.accountName}
							onChange={this.handleInputChange}
						/>
					</label>
					<label>
						Password
						<input
							name='password'
							type='password'
							value={this.state.password}
							onChange={this.handleInputChange}
						/>
					</label>
					<label>
						Verify password
						<input
							name='passwordVerify'
							type='password'
							value={this.state.passwordVerify}
							onChange={this.handleInputChange}
						/>
					</label>
					<button type='submit'>Sign up</button>
				</form>
			</div>
		);
	}
}

export default SignupForm;
